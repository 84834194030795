/* invest section */

.invest-port {
	position: relative;
	background-color: $clr-green;
	margin-top: -4.375rem;
	margin-bottom: -27.5em;
}
.invest-title h2 {
	position: relative;
	color: $clr-white;
	font-weight: $font-bold;
	line-height: 3.4375rem;
	margin: 3.75rem 4em 0;
	font-family: $font-family-montserrat;
	font-size: 3.6875rem;
	text-transform: uppercase;
	text-align: center;
}

.invest-title p {
	position: relative;
	color: $clr-white;
	font-weight: $font-regular;
	line-height: 2.3125rem;
	margin: 0.625rem 12em 2.5rem;
	font-size: 2.125rem;
	text-align: center;
	font-family: $font-family-opensans;
}
p.btn-p {
	margin-bottom: 3.75rem;
	margin-top: -1.25rem;
}

.distributor-port {
	position: relative;
	background-color: $clr-pink;
	width: 50%;
}

.publish-port {
	position: relative;
	background-color: $clr-blue;
	border-right: 0.0625rem solid $clr-white;
	width: 50%;
}

.publish-title h2 {
	position: relative;
	color: $clr-white;
	font-weight: $font-bold;
	line-height: 3.4375rem;
	margin: 3.75rem 4.5625rem 0;
	font-family: $font-family-montserrat;
	font-size: 3.6875rem;
	text-transform: uppercase;
	text-align: left;
}

.publish-title p {
	position: relative;
	color: $clr-white;
	font-weight: $font-regular;
	line-height: 1.5625rem;
	margin: 0.625rem 3.5em 0rem;
	font-size: 1.3125rem;
	text-align: left;
	font-family: $font-family-opensans;
}

p.publish-btn {
	margin: 1.875rem 3.5em 3.75rem;
}

p.btn-publish {
	margin: -0.625rem 3.4em 3.75rem;
	text-align: left;
}

.btn-style-one.blue:before {
	background-color: $clr-blue;
}

.distributor-port .btn-style-one:before {
	background-color: $clr-blue;
}

/* Advisors section */

.portfolio-sec-details {
	padding-bottom: 1.875rem !important;
	min-height: 73vh;
}

.port-detail-con {
	padding-top: 3.125rem;
}

.sec-title.port-text h2 {
	text-align: center;
	color: $clr-green;
	padding-top: 1em;
}

h2.home-top-invest {
	text-align: center;
	color: $clr-green;
	padding-top: 1.5em !important;
	font-size: 2.5625rem !important;
	font-family: $font-family-montserrat;
	line-height: 3.125rem !important;
	font-weight: 700 !important;
}
.MuiGrid-root.sec-title.port-text h2{
    margin-top: 10em;
}
.about-column.home-port.con-div {
	padding-top: 1.5625rem;
	margin-left: 0.9375rem;
}
.head-border-advisor {
	border-top: 0.0625rem solid $clr-bg-gray !important;
	width: 3.8125rem;
	margin: 1.25rem auto;
}
a.filters {
	color: $clr-text-five;
	font-size: 1rem;
	font-weight: $font-semibold;
}

span.arrow.ti-angle-down {
	color: $clr-green;
	font-weight: $font-xbold;
	font-size: 0.875rem;
	margin-left: 0.625rem;
}

.filter-div {
	text-align: right;
	padding-bottom: 0.625rem;
}

h2.port-name {
	font-size: 1rem;
	color: $clr-green;
	text-align: left;
	padding: 0.625rem 0;
	font-weight: $font-semibold;
	line-height: 1.3;
	text-transform: capitalize;
}

h2.port-exp {
	font-size: 0.875rem;
	color: $clr-text-four;
	text-align: left;
	font-weight: $font-semibold;
	line-height: 1rem;
	margin-top: 1.25rem;
	font-family: $font-family-opensans;
}

h2.port-heading {
	font-size: 1.4375rem;
	text-align: left;
	color: $clr-text-three;
	font-weight: $font-bold;
	line-height: 2rem;
	text-transform: capitalize;
	font-family: $font-family-opensans;
}

p.port-desc {
	font-size: 1rem;
	color: lr-text-five;
	text-align: left;
	margin-bottom: 0.3125rem;
	margin-left: 0.9375rem;
	font-weight: $font-regular;
	/* letter-spacing: 0.0625rem; */
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

p.port-desc1 {
	color: $clr-text-five;
	text-align: left;
	margin-bottom: 0.3125rem;
	margin-left: 0rem;
	/* letter-spacing: 0.0625rem; */
	font-family: $font-family-opensans;
	font-size: 1rem;
	font-weight: $font-semibold;
	line-height: 1.875rem;
}

p.port-desc.home-port-desc {
	font-size: 0.9375rem;
	color: $clr-text-five;
	text-align: left;
	margin-bottom: 0.3125rem;
	margin-left: 1rem;
	font-weight: $font-regular;
	line-height: 1.5;
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-line-clamp: 6;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-family: $font-family-opensans;
}

.port-desc.home-port-desc p {
	line-height: 1.375rem;
}

p.port-desc.home-port-desc ol {
	margin-left: 0.25rem;
	line-height: 1.375rem;
}

p.port-desc.home-port-desc ul {
	margin-left: 0.25rem;
	line-height: 1.375rem;
}
.testimonial-block {
	position: relative;
	margin-bottom: 3.75rem;
}

.testimonial-block .inner-box {
	position: relative;
	width: 34.0625rem;
	padding: 1.875rem 1.875rem;
	border: 0.0625rem solid $clr-bg-advisor;
	margin: 0 1.25rem;
	background-color: $clr-bg-advisor;
	border-radius: 0.3125rem;
	height: 15.625rem;
	box-shadow: $clr-box-shadow;
	background-position: 100% 0;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.testimonial-block .inner-box:before {
	position: absolute;
	content: '';
	left: 0rem;
	top: 0rem;
	right: 0rem;
	height: 0.3125rem;
	opacity: 0;
	transform: scale(0.3, 1);
	background-color: $clr-bg-advisor;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}
.inner-box:hover {
	background-color: $clr-box-hover;
	border: 0.0625rem solid $clr-box-hover;
	cursor: pointer;
}
.testimonial-block .inner-box:hover::before {
	opacity: 0;
	transform: scale(1, 1);
}
.vline {
	border-left: 0.0625rem solid $clr-bg-scrollbar;
	height: 11.5625rem;
	position: absolute;
	left: 24%;
}

.w-320 {
	// width: 20.9375rem;
	height: 11.875rem;
	overflow: hidden;
}

.head-border-port.home {
	margin-left: 1.0625rem;
}

h2.port-heading.home {
	margin-left: 0.9375rem;
}

.portlogo-resize {
	width: 22%;
}

.head-border-port {
	border-top: 0.1875rem solid $clr-pink;
	width: 2rem;
	margin: 0.625rem 0 0.75rem;
}

.port-logo {
	border: 0.1875rem solid $clr-green;
	border-radius: 3.125rem;
	width: 4.0625rem;
	height: 4.0625rem;
}

.port-logo img {
	border-radius: 3.125rem;
}

.load-btn {
	margin-bottom: 3.75rem;
}
p.port-desc.home-port-desc ol {
	/* margin-top: -0.5rem;
    margin-bottom: -0.3125rem; */
	counter-reset: item;
	color: $clr-text-five;
	padding-left: 0;
	margin-bottom: 0;
}

p.port-desc.home-port-desc ol li {
	list-style-type: decimal;
	display: block;
}

p.port-desc.home-port-desc ol li:before {
	content: counter(item) ". ";
	counter-increment: item;
	font-weight: bold;
	color: $clr-text-five;
	width: 1.25rem;
	margin-left: 1em;
}
p.port-desc.home-port-desc ul {
	/* margin-top: -0.5rem;
    margin-bottom: -0.3125rem; */
	color: $clr-text-five;
	padding-left: 2rem;
	margin-bottom: 0;
}

p.port-desc.home-port-desc ul li {
	list-style-type: disc;
}

p.port-desc.home-port-desc ul li:before {
	font-weight: bold;
	color: $clr-text-five;
	width: 1.25rem;
}

p.port-desc.home-port-desc br:empty {
	display: none;
}

p.port-desc.home-port-desc:empty {
	display: none;
}
p.portdetails-answer ul {
	/* margin-top: -0.5rem;
    margin-bottom: -0.3125rem; */
	color: $clr-text-five;
	padding-left: 2rem;
}

p.portdetails-answer ul li {
	list-style-type: disc;
}

p.portdetails-answer ul li:before {
	font-weight: bold;
	color: $clr-text-five;
	width: 1.25rem;
}

p.portdetails-answer br:empty {
	display: none;
}

p.portdetails-answer:empty {
	display: none;
}
p {
	margin-bottom: 0;
	line-height: 1.5625rem;
}
.product-link {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
p.port-desc.home-port-desc p {
	margin-bottom: 0;
}
.r-float {
	float: right;
}

/* broker section */

.broker-section {
	background-color: $clr-white;
	padding-bottom: 3.75rem;
}
.sec-title.broker-title h2 {
	color: $clr-pink;
	text-align: center;
	font-size: 2.125rem;
	font-weight: $font-bold;
	font-family: $font-family-montserrat;
	line-height: 2.625rem;
}
.vline2 {
	border-left: 0.0625rem solid $clr-border-one;
	height: 4.1875rem;
	position: absolute;
	left: 49.8%;
	margin-top: 0.3125rem;
}
.broker-logos {
	margin-left: 15.9em;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-1.MuiGrid-grid-md-1.MuiGrid-grid-lg-1.l-float.width-1per {
	max-width: 1.333333%;
}