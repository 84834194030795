/* dashboard sidebar menu */
header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.dashboard-appbar.mui-fixed {
	top: 5rem;
	/* background-color: #2B292F; */
	background-color: $clr-bg-dashboardmenu;
	box-shadow: none;
	width: 7%;
	right: 93.6%;
	position: absolute;
	border-bottom: 0.0625rem solid $clr-text-eight;
	display: none;
}

header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.dashboard-appbar.mui-fixed svg {
	font-size: 1.875rem !important;
}

.MuiDrawer-root.MuiDrawer-docked.dashboard-drawer .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
	background-color: $clr-bg-advisor;
	box-shadow: none;
	/* height: 70%; */
	top: 8.8125rem;
	position: absolute;
	max-height: 27.75rem;
	min-height: 27.75rem;
	z-index: 1;
}

.MuiDrawer-root.MuiDrawer-docked.dashboard-drawer button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
	color: $clr-border-one;
}

header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.dashboard-appbar.mui-fixed button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeMedium {
	color: $clr-border-one;
	margin-left: 0.625rem;
}

header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.dashboard-appbar.mui-fixed button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeMedium:hover {
	box-shadow: none !important;
}

.MuiDrawer-root.MuiDrawer-docked.dashboard-drawer button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium:hover {
	box-shadow: none !important;
}
.MuiDrawer-root.MuiDrawer-docked.dashboard-drawer svg {
	color: #797979;
	margin-left: 0.9375rem;
	font-size: 1.875rem;
}

.MuiDrawer-root.MuiDrawer-docked.dashboard-drawer .MuiListItemText-root span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
	color: $clr-text-nine;
	font-size: 1rem;
	font-family: $font-family-montserrat;
	font-weight: $font-regular;
	line-height: 2.3125rem;
	margin-left: -0.3125rem;
}

.MuiDrawer-root.MuiDrawer-docked.dashboard-drawer .MuiListItemButton-root:hover .MuiListItemText-root span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
	color: $clr-white;
}

.MuiDrawer-root.MuiDrawer-docked.dashboard-drawer .active .MuiListItemText-root span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
	color: $clr-bg-dashboard;
}

.MuiDrawer-root.MuiDrawer-docked.dashboard-drawer .MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root {
	border-bottom: 0.0625rem solid $clr-text-six;
}
.MuiDrawer-root.MuiDrawer-docked.dashboard-drawer .MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root:hover {
    background: $clr-text-nine;
}
.MuiListItemButton-root.MuiListItemButton-gutters.MuiButtonBase-root.active {
    background: $clr-green;
}
.MuiDrawer-root.MuiDrawer-docked.dashboard-drawer ul.MuiList-root.MuiList-padding{
	padding: 0;
}
.MuiListItemIcon-root .PortfolioImg{
	content: url("../../images/portfolio.png");
	margin-left: 0.9375rem;
}
.active .MuiListItemIcon-root .PortfolioImg{
	content: url("../../images/portfolio-w.png");
	margin-left: 0.9375rem;
}
.MuiListItemButton-root:hover .PortfolioImg{
	content: url("../../images/portfolio-w.png");
	margin-left: 0.9375rem;
}
.MuiListItemIcon-root .OrderBookImg{
	content: url("../../images/order-book.png");
	margin-left: 0.9375rem;
}
.active .MuiListItemIcon-root .OrderBookImg{
	content: url("../../images/order-book-w.png");
	margin-left: 0.9375rem;
}
.MuiListItemButton-root:hover .OrderBookImg{
	content: url("../../images/order-book-w.png");
	margin-left: 0.9375rem;
}
.MuiListItemIcon-root .SubscriptionImg{
	content: url("../../images/subscription.png");
	margin-left: 0.9375rem;
}
.active .MuiListItemIcon-root .SubscriptionImg{
	content: url("../../images/subscription-w.png");
	margin-left: 0.9375rem;
}
.MuiListItemButton-root:hover .SubscriptionImg{
	content: url("../../images/subscription-w.png");
	margin-left: 0.9375rem;
}
.MuiListItemIcon-root .RiskProfileImg{
	content: url("../../images/risk-profile.png");
	margin-left: 0.9375rem;
}
.active .MuiListItemIcon-root .RiskProfileImg{
	content: url("../../images/risk-profile-w.png");
	margin-left: 0.9375rem;
}
.MuiListItemButton-root:hover .RiskProfileImg{
	content: url("../../images/risk-profile-w.png");
	margin-left: 0.9375rem;
}
.MuiListItemIcon-root .HomeImg{
	content: url("../../images/home.png");
	margin-left: 0.9375rem;
}
.MuiListItemButton-root:hover .HomeImg{
	content: url("../../images/home-w.png");
	margin-left: 0.9375rem;
}
.active .MuiListItemIcon-root .HomeImg{
	content: url("../../images/home-w.png");
	margin-left: 0.9375rem;
}
.dashboard-toggle {
    /* position: fixed; */
	position: absolute;
    top: 6rem;
}
.dashboard-toggle button{
	color: $clr-border-one !important;
    left: 0.9375rem;
}
.dashboard-toggle button:hover{
	box-shadow: none !important;
}
.dashboard-toggle button svg {
    font-size: 1.75rem;
}
/* dashboard sidebar menu */

/* mobile dashboard menu */
.nav.dashmb {
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 6.875rem;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.2);
    background-color: $clr-bg-main;
    display: flex;
    overflow-x: auto;
	z-index: 1000;
}
.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 3.125rem;
    overflow: hidden;
    white-space: nowrap;
    font-family: $font-family-montserrat;
    font-size: 0.625rem;
    color: #797979;
	font-weight: $font-semibold;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
}
/* .nav__link:hover {
    background-color: #eeeeee;
} */
.nav__link--active {
    color: $clr-text-one;
	font-weight: $font-black;
}
button.nav__link.active {
    color: $clr-black;
}
.nav__icon {
    font-size: 1.125rem;
}
.dm-desktop-hide{
	display: none;
}
span.nav__text {
    margin-top: 0.75rem;
}
.dashmob-mar{
	margin: 1.25rem 0 -2.1875rem !important;
}
button.nav__link {
    background-color: $clr-bg-main;
}
button.nav__link:hover {
    background-color: transparent;
    box-shadow: none;
}
/* mobile dashboard menu */