header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionFixed.main-header.mui-fixed {
	padding-right: 0rem !important;
	position: absolute;
	/* border-bottom: 0.0625rem solid $clr-border-one; */
}

.main-header .header-upper {
	position: relative;
	z-index: 1;
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	background-color: $clr-black;
}
.main-header .header-upper .outer-box {
	position: absolute;
	right: 0rem;
	margin-left: 0rem;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}
.main-header .header-upper .upper-right {
	position: relative;
}
.main-header .header-upper .inner-container {
	position: relative;
}
.main-header .header-upper .logo-box {
	position: relative;
	z-index: 10;
	padding: 0.75rem 0rem 0;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}
.main-header .header-upper .logo-box .logo {
	position: relative;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}
.main-header .nav-outer {
	position: relative;
	/* padding-left:18.4375rem; */
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}
.main-menu {
	position: relative;
	float: left;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}
.main-menu .navbar-collapse {
	padding: 0rem;
	display: block;
}

.main-menu .navigation {
	position: static;
	margin: 0.8rem 0; 
	width: 100%;
}
.main-menu .navigation>li {
	position: relative;
	float: none;
	display: inline-block;
	margin-right: 2.8125rem;
	text-align: left;
	font-family: $font-family-opensans;
	font-weight: $font-medium;
	color: $clr-text-ten;
	cursor: pointer;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.main-menu .navigation>li:last-child {
	margin-right: 0rem;
}
.main-menu .navigation > li > a,
.main-menu .navigation > li button {
	position: relative;
	display: block;
	color: $clr-text-ten;
	text-align: center;
	line-height: 0.625rem;
	letter-spacing: 0rem;
	opacity: 1;
	font-weight: $font-medium;
	padding: 1.125rem 0rem;
	font-size: 1rem;
	text-decoration: none;
	font-family: $font-family-opensans;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.main-menu .navigation>li:hover>a,
.main-menu .navigation>li.current>a {
	color: $clr-green;
}

.main-menu .navigation>li:hover button,
.main-menu .navigation>li.current button {
	color: $clr-green;
	background: transparent;
}

span.signhover:hover {
    color: $clr-green;
}

.main-menu .navigation>li:hover>a,
.main-menu .navigation>li.current>a,
.main-header.light-version .main-menu .navigation>li:hover>a,
.main-header.light-version .main-menu .navigation>li.current>a {
	opacity: 1;
}

.main-menu .navigation>li>ul {
	position: absolute;
	left: -1.875rem;
	top: 100%;
	width: 14.375rem;
	z-index: 100;
	display: none;
	opacity: 0;
	visibility: hidden;
	transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-webkit-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	padding: 1.25rem 0.9375rem;
	border-radius: 0.625rem;
	background-color: $clr-white;
	box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.10);
}

.main-menu .navigation>li>ul.from-right {
	left: auto !important;
	right: -1.875rem !important;
}

.main-menu .navigation>li>ul>li {
	position: relative;
	width: 100%;
}

.main-menu .navigation>li>ul>li>a {
	position: relative;
	display: block;
	padding: 0.375rem 1.125rem;
	line-height: 1.5rem;
	font-weight: $font-medium;
	font-size: 0.9375rem;
	text-transform: capitalize;
	color: #222222;
	padding-left: 0.625rem;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>a:before {
	position: absolute;
	top: -0.375rem;
	left: 0.5rem;
	color: transparent;
	content: '.';
	text-shadow: 0 0 transparent;
	font-size: 2em;
	line-height: 1em;
	-webkit-transition: text-shadow 0.3s, color 0.3s;
	-moz-transition: text-shadow 0.3s, color 0.3s;
	transition: text-shadow 0.3s, color 0.3s;
	pointer-events: none;
	animation: opacitychange 1s ease-in-out infinite;
}
.header-mui {
	display: inline-block !important;
	background-color: $clr-bg-white;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
	// margin-top: 1.875rem;
	margin-left: -0.9375rem;
	border-radius: 0.1875rem;
	background-color: $clr-bg-gray-dark;
}

#basic-menu1 .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
	margin-top: 1.7rem;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list {
	padding: 0.3125rem 0.625rem;
	background-color: $clr-white;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list a {
	text-decoration: none;
}
li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
	font-family: $font-family-opensans;
	font-size: 1.0625rem;
	color: $clr-text-one;
	font-weight: $font-bold;
	padding: 0.5rem 0rem 0.5rem;
	min-width: 7.5rem;
	/* border-bottom: 0.0625rem solid $clr-border-one; */
}

// ul.MuiList-root.MuiList-padding.MuiMenu-list span li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
// 	/* border-bottom: none; */
// 	/* border-bottom: 0.0625rem solid $clr-border-one; */
// }
li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.border-menu {
	border-bottom: 0.0625rem solid $clr-border-one !important;
}

/* mobile menu */
nav.mobilemenu {
	background-color: $clr-white;
	height: 4.0625rem;
  }
  
  #menuToggle {
	display: flex;
	flex-direction: column;
	position: relative;
	top: 1.5625rem;
	left: 0rem;
	z-index: 1;
	-webkit-user-select: none;
	user-select: none;
	width: 2.5rem;
	// display: none;
  }
  
  #menuToggle input
  {
	display: flex;
	width: 2.5rem;
	height: 2rem;
	position: absolute;
	cursor: pointer;
	opacity: 0;
	z-index: 2;
  }
  
  #menuToggle span.menuToggle
  {
	display: flex;
	width: 1.3125rem;
	height: 0.125rem;
	margin-bottom: 0.14375rem;
	position: relative;
	background: $clr-bg-gray;
	border-radius: 0.1875rem;
	z-index: 1;
	transform-origin: 0.3125rem 0rem;
	transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
				background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
				opacity 0.55s ease;
  }
  
  #menuToggle span:first-child
  {
	transform-origin: 0% 0%;
  }
  
  #menuToggle span:nth-last-child(2)
  {
	transform-origin: 0% 100%;
	height: 0.15625rem;
  }
  
  #menuToggle input:checked ~ span
  {
	opacity: 1;
	transform: rotate(45deg) translate(-0.1875rem, -0.0625rem);
  }
  #menuToggle input:checked ~ span:nth-last-child(3)
  {
	opacity: 0;
	transform: rotate(0deg) scale(0.2, 0.2);
  }
  
  #menuToggle input:checked ~ span:nth-last-child(2)
  {
	transform: rotate(-45deg) translate(0, -0.0625rem);
  }
  
  #menu
  {
	position: fixed;
    /* width: 100%; */
    height: 110%;
	/* margin: -3.125rem 0 0 -3.125rem; */
	margin: -4.375rem 0 0 -20em;
	padding: 2.5rem;
    padding-top: 7.5rem;
	background-color: $clr-white;
	list-style: none;
	-webkit-font-smoothing: antialiased;
	transform-origin: 0% 0%;
	transform: translate(-100%, 0);
	transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }
  
  #menu li
  {
	padding: 0.625rem 0;
	transition-delay: 2s;
  }
  
  #menuToggle input:checked ~ ul
  {
	transform: none;
  }
  ul#menu a{
	 color: $clr-bg-gray;
	 font-size: 1rem;
	 font-family: $font-family-opensans;
	 font-weight: $font-semibold; 
  }
  
/* end mobile menu*/
.mobilemenu{display: none;}
.desktop-hide{display: none !important;}

a.theme-btn.btn-style-one.get-started.login-letter {
	background-color: $clr-green;
	color: $clr-white;
	font-size: 1.5625rem;
}